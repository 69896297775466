import React, { Fragment } from 'react';
import banner from '../../images/slide-3.jpg'
import { Link } from 'react-router-dom'
import demo1 from './image/1.jpg'
import demo2 from './image/2.jpg'
import demo3 from './image/3.jpg'
import demo4 from './image/4.jpg'

import './style.scss'


const PreviewPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <div className="preview-area">
                <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
                    <div className="container-fluid">
                        <div className="section-title">
                            <h2>Portfar - Personal Portfolio React Template</h2>
                            <a target="_blank" rel="noopener noreferrer" className="template-btn" href="https://themeforest.net/item/portfar-personal-portfolio-react-template/33708791">Buy Now</a>
                        </div>
                    </div>
                    <div className="white_svg">
                        <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                            <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                        </svg>
                    </div>
                </div>
                <div className="demo-main-area">
                    <div className="container">
                        <div className="demo-wrap">
                            <div className="section-title">
                                <h2>Portfar Demo</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="demo-img">
                                        <Link target="_blank"  onClick={ClickHandler} className="d-img" to="/home">
                                            <img src={demo1} alt="" />
                                        </Link>
                                        <h4><Link target="_blank" onClick={ClickHandler} to="/home">Stylist Menu Dark Version</Link></h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="demo-img">
                                        <a target="_blank" rel="noopener noreferrer" href="http://portfar-light-react.themepresss.com/" onClick={ClickHandler} className="d-img">
                                            <img src={demo2} alt="" />
                                        </a>
                                        <h4><a target="_blank" rel="noopener noreferrer" href="http://portfar-light-react.themepresss.com/" onClick={ClickHandler} >Stylist Menu Light Version</a></h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="demo-img">
                                        <a target="_blank" rel="noopener noreferrer" href="http://portfar-react-nav-dark.themepresss.com/" onClick={ClickHandler} className="d-img">
                                            <img src={demo3} alt="" />
                                        </a>
                                        <h4><a target="_blank" rel="noopener noreferrer" href="http://portfar-react-nav-dark.themepresss.com/" onClick={ClickHandler} >Nav Menu Dark Version</a></h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="demo-img">
                                        <a target="_blank" rel="noopener noreferrer" href="http://portfar-react-nav-light.themepresss.com/" onClick={ClickHandler} className="d-img">
                                            <img src={demo4} alt="" />
                                        </a>
                                        <h4><a target="_blank" rel="noopener noreferrer" href="http://portfar-react-nav-light.themepresss.com/" onClick={ClickHandler} >Nav Menu Light Version</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white_svg">
                        <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                            <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                        </svg>
                    </div>
                </div>
                <div className="pr-footer">
                    <div className="section-title">
                        <h2>Thank You!</h2>
                        <a target="_blank" rel="noopener noreferrer" className="template-btn" href="https://themeforest.net/item/portfar-personal-portfolio-react-template/33708791">Buy Now</a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
export default PreviewPage;


