import React, { Component} from 'react';
import { Link} from 'react-scroll'
import Logo from '../../images/logo.png';
import Home_1 from '../../images/home/img-1.jpg';
import Home_2 from '../../images/home/img-2.jpg';
import Home_3 from '../../images/home/img-3.jpg';
import Home_4 from '../../images/home/img-4.jpg';
import Home_5 from '../../images/home/img-5.jpg';
import Home_6 from '../../images/home/img-6.jpg';

import {NavLink} from 'react-router-dom'

class Header extends Component {

    state = {
        isOpen: false,

    }
    
    render() {

        return(
            <header id="header" className="site-header header-style-1">
                <nav className="navigation navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <NavLink className="navbar-brand" to="/home">
                                <img src={Logo} alt="" />
                            </NavLink>
                        </div>
                        <div id="navbar" className={ this.state.isOpen ? "navbar-collapse navigation-holder active" : "navbar-collapse navigation-holder"}>
                            <div className="navbar-header">
                                <NavLink className="navbar-brand" to="/home">
                                    <img src={Logo} alt="" />
                                </NavLink>
                            </div>
                            <button className="close-navbar" onClick={() => this.setState({isOpen: false})}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <button className="close-navbar-2" onClick={() => this.setState({isOpen: false})}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <ul className="nav navbar-nav">
                                <li className="home">
                                    <Link activeClass="active" to="home" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_1} alt=""/>
                                    </Link>
                                </li>
                                <li className="about">
                                    <Link activeClass="active" to="about" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_2} alt=""/>
                                    </Link>
                                </li>
                                <li className="service">
                                    <Link activeClass="active" to="service" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_3} alt=""/>
                                    </Link>
                                </li>
                                <li className="protfolio">
                                    <Link activeClass="active" to="portfolio" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_4} alt=""/>
                                    </Link>
                                </li>
                                <li className="blog">
                                    <Link activeClass="active" to="blog" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_5} alt=""/>
                                    </Link>
                                </li>
                                <li className="contact">
                                    <Link activeClass="active" to="contact" spy={true} smooth={true}  duration={500}>
                                        <img src={Home_6} alt=""/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-open-btn-holder">
                            <button className="menu-open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;